<template>
  <div class="reminder">
    <h2 class="m-4">
      Reminders
    </h2>
    <div class="scroll-list custom-scroll">
      <div v-for="item in reminderList" :key="item.label" class="mx-4">
        <h3 class="text-primary mt-2">
          {{ item.label }} Reminder
        </h3>
        <b-form-group v-for="option in item.options" :key="option.item" :label="option.title" label-class="font-size-base mb-0" class="mb-2">
          {{ option.text }}
        </b-form-group>
      </div>
    </div>

    <hr>
    <b-link class="reminder-link d-block text-decoration-none px-4 pb-4" @click="openReminderModal">
      Edit reminders
    </b-link>
  </div>
</template>

<script>
import reminderUnitList from '@/common/reminderUnitList'
import cycleTypeList from '@/common/cycleTypeList'

export default {
  name: 'User',
  props: {
    reminderData: {
      type: Array,
      required: true
    }
  },
  computed: {
    reminderUnitList () {
      return reminderUnitList
    },
    reminderList () {
      return cycleTypeList.filter(p => p.label !== 'OtherType').map(p => {
        return {
          label: p.label,
          options: p.options.map(item => {
            const reminder = this.reminderData.find(p => p.item === item.value)
            return {
              item: item.value,
              title: item.text,
              text: 'Every ' + reminder.value + ' ' + this.reminderUnitList.find(x => x.value === reminder.unit)?.text
            }
          })
        }
      })
    }
  },
  methods: {
    openReminderModal () {
      this.$emit('openReminderModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.reminder {
  width: 22rem;

  &-link {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }
}
.scroll-list{
  max-height: 330px;
  height: 310px;
  overflow-y: auto;
}
</style>
