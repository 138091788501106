import Layout from '@/layouts/main'

const productRouter = {
  path: '/product',
  component: Layout,
  children: [
    {
      name: 'Product',
      path: '',
      component: () => import('@/views/product'),
      meta: {
        needAuth: true,
        rootMenu: 'Product',
        breadcrumb: [
          {
            text: 'Product Info' // 'Product Info'
          }
        ]
      }
    },
    {
      name: 'ProductDetail',
      path: 'detail',
      component: () => import('@/views/product/detail'),
      meta: {
        needAuth: true,
        rootMenu: 'Product',
        breadcrumb: [
          {
            text: 'Product Info' // 'Product Info'
          }
        ]
      }
    }
  ]
}

export default productRouter
