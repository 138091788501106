const store = {
  namespaced: true,
  state: () => ({
    selected: null,
    selectedHistory: null,
    historyList: []
  }
  ),
  mutations: {
    SET_SELECTED (state, payload) {
      state.selected = payload
    },
    SET_SELECTED_HISTORY (state, payload) {
      state.selectedHistory = payload
    },
    SET_HISTORY_LIST (state, payload) {
      state.historyList = payload
    },
    CLEAR (state) {
      state.selected = null
      state.historyId = -1
      state.historyList = []
    }
  }
}
export default store
