import Vue from 'vue'
import Vuex from 'vuex'
import product from './product'
import { getToken, saveToken, removeToken } from '@/common/token.service'
import { login, logout } from '@/api/auth'
import { getUserData } from '@/api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
    userData: {
      id: null,
      name: '',
      email: '',
      role: null,
      created_at: '',
      updated_at: ''
    },
    clinic_name: '',
    breadcrumb: []
  },
  getters: {
    authHeader (state) {
      const token = state.token
      return token ? `Bearer ${token}` : ''
    }
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_CLINICNAME (state, name) {
      state.clinic_name = name
    },
    SET_USERDATA (state, userData) {
      state.userData = userData
    },
    SET_BREADCRUMB (state, breadcrumb) {
      state.breadcrumb = breadcrumb
    }
  },
  actions: {
    login ({ commit }, form) {
      return new Promise((resolve, reject) => {
        login(form).then(res => {
          const token = res.data.data.original.access_token
          commit('SET_TOKEN', token)
          saveToken(token)
          resolve(token)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          const emptyUserData = {
            id: null,
            name: '',
            email: '',
            role: null,
            created_at: '',
            updated_at: ''
          }
          commit('SET_TOKEN', null)
          commit('SET_USERDATA', emptyUserData)
          removeToken()
          resolve()
        }).catch(err => {
          resolve(err)
        })
      })
    },
    getUserData ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserData().then(res => {
          commit('SET_USERDATA', res.data.data)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
    product
  }
})
