<template>
  <header class="header d-flex justify-content-between align-items-center mb-8">
    <div class="header-breadcrumb">
      <h1 class="text-primary mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <b-button
            v-if="showBack"
            variant="secondary"
            class="btn-action mr-6 text-primary btn-back"
            @click="$router.back()"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
          </b-button>
          <b-breadcrumb :items="breadcrumb" :active-class="'h1'" />
        </div>
      </h1>
    </div>

    <div id="header-toolbar" class="position-relative">
      <ul class="header-toolbar list-unstyled mb-0">
        <li>
          <b-link
            :class="{ active: currentItem === 'user' }"
            @click="currentItem = 'user'"
          >
            <font-awesome-icon
              :icon="['fas', 'user-circle']"
              size="lg"
              fixed-width
            />
          </b-link>
        </li>
        <li>
          <b-link
            :class="{ active: currentItem === 'reminder' }"
            @click="currentItem = 'reminder'"
          >
            <img
              src="~@/assets/images/icon-reminder.png"
              alt="reminder"
              class="menu-img mx-2"
            >
            <img
              src="~@/assets/images/icon-reminder-active.png"
              alt="reminder"
              class="menu-img-active mx-2"
            >
          </b-link>
        </li>
        <li>
          <b-link
            :class="{ active: currentItem === 'notify' }"
            @click="currentItem = 'notify'"
          >
            <font-awesome-icon :icon="['fas', 'bell']" size="lg" fixed-width />
          </b-link>
        </li>
      </ul>

      <div v-if="currentItem" class="header-toolbar-content">
        <component
          :is="toolbarContentComponent"
          :reminder-data="reminderData"
          @openReminderModal="openReminderModal"
          @openLanguageModal="openLanguageModal"
        />
      </div>
    </div>

    <ReminderEditModal ref="reminderEditModal" @update="getReminderData" />

    <language-modal ref="languageModal" />
  </header>
</template>

<script>
import { getReminderData } from '@/api/reminder'
import User from './headerUser'
import Reminder from './headerReminder'
import Notify from './headerNotify'
import ReminderEditModal from './reminderEditModal'
import { getCorporateInfo } from '@/api/user'
import LanguageModal from './languageModal.vue'

export default {
  name: 'Header',
  components: {
    User,
    Reminder,
    Notify,
    ReminderEditModal,
    LanguageModal
  },
  data () {
    return {
      currentItem: null,
      reminderData: []
    }
  },
  computed: {
    toolbarContentComponent () {
      switch (this.currentItem) {
        case 'user':
          return 'User'
        case 'reminder':
          return 'Reminder'
        default:
          return 'notify'
      }
    },
    breadcrumb () {
      const breadcrumb = this.$store.state.breadcrumb
      return [{ text: this.$store.state.clinic_name, to: { name: 'Dashboard' } }, ...breadcrumb]
    },
    showBack () {
      return this.$route.name === 'ProductDetail'
    }
  },
  mounted () {
    this.getCorporateInfo()
    this.getReminderData()
    window.document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    window.document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    getReminderData () {
      getReminderData().then((res) => {
        this.reminderData = res.data.data
      })
    },
    clickHandler (e) {
      const isMenu = e.path.some((el) => el.id === 'header-toolbar')
      if (!isMenu) this.currentItem = null
    },
    openReminderModal () {
      this.currentItem = null
      this.$refs.reminderEditModal.show(this.reminderData)
    },
    async getCorporateInfo () {
      return getCorporateInfo().then((res) => {
        this.$store.commit('SET_CLINICNAME', res.data.data.clinic_name)
      })
    },
    openLanguageModal () {
      this.currentItem = null
      this.$refs.languageModal.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~@/assets/style/variables";

.header {
  &-breadcrumb {
    margin-left: 6.5rem;
  }

  .breadcrumb-item,.breadcrumb {
      color: $primary;
      font-size: 2.25rem;
    span,a {
      color: $primary;
      font-size: 2.25rem;
    }
  }

  &-toolbar {
    display: flex;
    background-color: $primary;
    border-radius: 1.25rem;
    position: relative;
    box-shadow: -10px -10px 20px 0 #fff,
      10px 10px 20px 0 rgba(174, 174, 192, 0.4),
      inset 4px 4px 6px 0 rgba(255, 255, 255, 0.4);
    padding: 0.75rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      background: linear-gradient(-42deg, #0077c6 0%, #00a7e0 95%);
      color: #fff;
      box-shadow: none;
      margin: 0 0.75rem;

      .menu-img-active {
        display: none;
      }

      &:hover {
        background: #f0f0f3;
        color: $primary;

        .menu-img {
          display: none;
        }

        .menu-img-active {
          display: inline-block;
        }
      }

      &.active {
        background: #f0f0f3;
        color: $primary;

        .menu-img {
          display: none;
        }

        .menu-img-active {
          display: inline-block;
        }
      }
    }

    &-content {
      position: absolute;
      top: 100%;
      right: 0;
      border-radius: 0.75rem;
      backdrop-filter: blur(10px);
      box-shadow: 10px 10px 20px 0 rgba(174, 174, 192, 0.4),
        inset 2px 2px 6px 0 rgba(255, 255, 255, 0.8);
      background-color: rgba(242, 243, 247, 0.4);
      z-index: 10;
      padding: 1rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
