const cycleTypeList = [
  {
    label: 'Testings',
    options: [
      { value: 10, text: 'Leakage Test' },
      { value: 11, text: 'Helix Test' },
      { value: 12, text: 'Bowie-Dick Test' },
      { value: 13, text: 'Biological Indicator Test' },
      { value: 14, text: 'Chemical Indicator Test' }
    ]
  },
  {
    label: 'Maintenance',
    options: [
      { value: 100, text: 'Replace Exhaust Filter' },
      { value: 101, text: 'Replace Air Filter' },
      { value: 102, text: 'Replace Door Gasket' },
      { value: 103, text: 'Replace Clean Water' },
      { value: 104, text: 'Check Safety Valve' },
      { value: 105, text: 'Check Power Cord' },
      { value: 106, text: 'Check Quality of Water for Sterilization' },
      { value: 107, text: 'Temperature Calibration' },
      { value: 108, text: 'Replace Steam Trap' },
      { value: 109, text: 'Annual Maintenance' }
    ]
  },
  {
    label: 'Cleaning',
    options: [
      { value: 200, text: 'Wipe Shell' },
      { value: 201, text: 'Wipe Chamber' },
      { value: 202, text: 'Wipe Door Gasket' },
      { value: 203, text: 'Clean Sterilization Accessories' },
      { value: 204, text: 'Clean Water Tank with Filter' },
      { value: 205, text: 'Clean Temperature Sensor' },
      { value: 206, text: 'Clean Chamber' },
      { value: 207, text: 'Clean Dran Filter' },
      { value: 208, text: 'Clean Temperature Sensor of Liquid' },
      { value: 209, text: 'Clean Water Level Sensor of Chamber' },
      { value: 210, text: 'Clean Water Level Sensor of Tank' },
      // { value: 211, text: 'Clean Steam Generator' },
      { value: 212, text: 'Clean Filter of Cooling Fan' }
    ]
  },
  {
    label: 'OtherType',
    options: [
      { value: 0, text: 'Program' },
      { value: 99, text: 'Other' }
    ]
  }
]

export default cycleTypeList
