import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import loginRouter from './login.js'
import dashboardRouter from './dashboard.js'
import productRouter from './product.js'
import faqRouter from './faq.js'
import directoryRouter from './directory.js'
import profileRouter from './profile.js'

Vue.use(VueRouter)

const routes = [
  loginRouter,
  dashboardRouter,
  productRouter,
  faqRouter,
  directoryRouter,
  profileRouter,
  { path: '*', redirect: '/login' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit('SET_BREADCRUMB', to.meta.breadcrumb ?? [])

  if (!to.meta.needAuth) return next()

  const hasToken = !!store.getters.authHeader
  if (!hasToken) return next({ name: 'Login' })

  if (to.name === 'Login') return next({ name: 'Dashboard' })

  if (!store.state.userData.id) await store.dispatch('getUserData')
  next()
})

export default router
