<template>
  <ul class="menu list-unstyled mb-0">
    <li :class="{ active: isActiveItem('Dashboard') }">
      <b-link :to="{ name: 'Dashboard' }">
        <img src="~@/assets/images/icon-menu-dashboard.png" alt="partner" class="menu-img mx-2">
        <img src="~@/assets/images/icon-menu-dashboard-active.png" alt="partner" class="menu-img-active mx-2">
        <span>Dashboard</span>
      </b-link>
    </li>
    <li :class="{ active: isActiveItem('Product') }">
      <b-link :to="{ name: 'Product' }">
        <img src="~@/assets/images/icon-menu-product-info.png" alt="service" class="menu-img mx-2">
        <img src="~@/assets/images/icon-menu-product-info-active.png" alt="service" class="menu-img-active mx-2">
        <span>Product Info</span>
      </b-link>
    </li>
    <li :class="{ active: isActiveItem('Faq') }">
      <b-link :to="{ name: 'FaqList' }">
        <img src="~@/assets/images/icon-menu-faq.png" alt="faq" class="menu-img mx-2">
        <img src="~@/assets/images/icon-menu-faq-active.png" alt="faq" class="menu-img-active mx-2">
        <span>Technical Support</span>
      </b-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    isActiveItem (rootMenu) {
      return this.$route.meta.rootMenu === rootMenu
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.menu {
  display: flex;
  flex-direction: column;
  background-color: $primary;
  border-radius: 1.25rem;
  box-shadow:
    -10px -10px 20px 0 #fff,
    10px 10px 20px 0 rgba(174, 174, 192, 0.4),
    inset 4px 4px 6px 0 rgba(255, 255, 255, 0.4);
  z-index: 10;
  padding: 0.75rem;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background: linear-gradient(-42deg, #0077c6 0%,#00a7e0 95%);
    margin: 0.75rem 0;

    span {
      display: none;
    }

    .menu-img-active {
      display: none;
    }

    &:hover {
      a {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        padding-right: 2rem;
        background-color: rgba(242, 243, 247, 0.7);
        border-radius: 1.5rem;
        text-decoration: none;
        font-weight: 500;
        color: $primary;
        box-shadow: 10px 10px 20px 0 rgba(174, 174, 192, 0.4), inset 2px 2px 6px 0 rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(10px);
        top: 0;
        bottom: 0;
        left: 0;
        margin: 0;
      }

      .menu-img {
        display: none;
      }

      .menu-img-active {
        display: inline-block;
      }

      span {
        white-space: nowrap;
        display: inline-block;
      }
    }

    &.active {
      background: #f0f0f3;

      .menu-img {
        display: none;
      }

      .menu-img-active {
        display: inline-block;
      }
    }
  }
}
</style>
