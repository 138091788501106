<template>
  <div class="layout-login">
    <header class="pt-10 pl-12">
      <img src="@/assets/images/logo-login.png" height="78px" alt="logo">
    </header>
    <main class="layout-login-main">
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'Layout'
}
</script>

<style lang="scss" scoped>
.layout-login {
  height: 100vh;
  background-color: #00a5bd;
  // background-image: url('~@/assets/images/bg-login.png');
  background-size: cover;
  background-position: center center;
  min-width: 1440px;

  &-main {
    padding-top: 6rem;
  }
}
</style>
