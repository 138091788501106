<template>
  <b-modal
    id="reminder-edit-modal"
    title="Edit reminders"
    cancel-title="CANCEL"
    ok-title="SAVE"
    body-class="mx-4"
    size="xl"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="reminder-modal-scroll custom-scroll px-4">
      <div v-for="(item,ii) in form" :key="item.label">
        <h3 class="text-primary mb-4">
          {{ item.label }} Reminder
        </h3>
        <b-row>
          <b-col v-for="(option,oi) in item.options" :key="option.item" cols="4">
            <b-form-group
              :label="option.title"
              label-class="font-size-base"
              :state="$v.form.$each.$iter[ii].options.$each.$iter[oi].value.$anyDirty ? !$v.form.$each.$iter[ii].options.$each.$iter[oi].value.$anyError : null"
            >
              <div class="d-flex align-items-center">
                Every
                <b-form-input
                  v-model.number="option.value"
                  type="number"
                  class="mx-4"
                  placeholder="Enter Number"
                  :min="0"
                />
                <b-form-select
                  v-model="option.unit"
                  :options="reminderUnitList"
                  :state="$v.form.$each.$iter[ii].options.$each.$iter[oi].unit.$anyDirty ? !$v.form.$each.$iter[ii].options.$each.$iter[oi].unit.$anyError : null"
                  :disabled="option.unit === 99"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Select
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <b-form-invalid-feedback
                :state="$v.form.$anyDirty ? ($v.form.$each.$iter[ii].options.$each.$iter[oi].value.required && $v.form.$each.$iter[ii].options.$each.$iter[oi].unit.required) : null"
              >
                {{ option.title }} is required
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :state="$v.form.$anyDirty ? ($v.form.$each.$iter[ii].options.$each.$iter[oi].value.minValue && $v.form.$each.$iter[ii].options.$each.$iter[oi].unit.required) : null"
              >
                {{ option.title }} need more than 0
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <hr>
  </b-modal>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import reminderUnitList from '@/common/reminderUnitList'
import { updateReminderData } from '@/api/reminder'
import cycleTypeList from '@/common/cycleTypeList'

export default {
  name: 'ReminderEditModal',
  data () {
    return {
      form: []
    }
  },
  validations () {
    return {
      form: {
        $each: {
          options: {
            $each: {
              value: {
                required,
                minValue: minValue(0)
              },
              unit: { required }
            }
          }
        }
      }
    }
  },
  computed: {
    reminderUnitList () {
      return reminderUnitList
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('reminder-edit-modal')
      this.form = cycleTypeList.filter(p => p.label !== 'OtherType').map(p => {
        return {
          label: p.label,
          options: p.options.map(item => {
            const reminder = data.find(p => p.item === item.value)
            return {
              item: item.value,
              title: item.text,
              value: reminder.value,
              unit: reminder.unit
            }
          })
        }
      })
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('reminder-edit-modal')
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      const data = {
        reminders: this.form.map(p => p.options).flat().map(p => {
          return {
            item: p.item,
            value: p.value,
            unit: p.unit
          }
        })
      }
      updateReminderData(data).then(() => {
        this.$emit('update')
        this.hide()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reminder-modal-scroll {
  max-height: 500px;
  overflow: auto;
}
</style>
