import apiService from '@/common/api.service'

export function getReminderData () {
  return apiService({
    url: '/api/reminder',
    method: 'get'
  })
}

export function updateReminderData (data) {
  return apiService({
    url: '/api/reminder',
    method: 'patch',
    data
  })
}
